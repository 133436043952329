import React, { useEffect, useState } from 'react';
import Pagination from "../Common/Pagination"
import * as HELPER from "./AuctionCommonData";
import { withRouter } from "react-router-dom";
import AuctionService from '../../../service/AuctionService';

function UpcommingAuctionTable(props) { 
    
    const [listData, setListData] = useState([]);
    // const [endDateArray, setEndDateArray] = useState({});

    const getAuctionInventory = (auctionId) => {
        props.history.push("/auction-inventory/"+auctionId);

    }

    
    const datetimeFormat =  (endate) => {
        
        const datetime =  HELPER.getRemainingTime(endate)
        return datetime; 
    }


    useEffect(()=> {

        setListData(props.listData);

        let intervalManger = setInterval(()=> {
                
            // if(props.listData && props.listData.length && !props.loading){

            //     props.sendRequestDataToParent({})
            // }

            let endDates = {}
            if(props.listData && props.listData.length && !props.loading){
                for(let auction of props.listData){

                    if( new Date(auction.start_date_time).getTime() - new Date().getTime() >0){
                        endDates = {  ...endDates, [auction.id]: {end_date_time: auction.start_date_time}  }
                    }
                    // setEndDateArray(endDates)
                
                }
                
            }
            if(props.listData && props.listData.length && !props.loading && Object.keys(endDates).length !== props.listData.length){
                clearInterval(intervalManger)
                props.sendRequestDataToParent({byPassRedis: true})
            } 
            
        },1000);

    }, [props])

    /**
     * HANDLE PAGINATION DATA
     * @param {*} data 
     */
    const handleRequestData = async (data) => {

        props.sendRequestDataToParent(data)
    }

    // render (){
        const { paginationData, page, runPaginationFunc, loading, totalLengthWithoutPagination } = props;     
        return (
            <div className="auction-table-data">
                <table>
                    <thead>
                        <tr>
                            <th>Auction Name</th>
                            <th>Location</th>
                            <th>Auction Type</th>
                            <th>Inventory</th>
                            <th>Starts In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (listData && listData.length && !loading)?
                            listData.map((el, index)=>{
                                return (<tr onClick={()=>getAuctionInventory(el.hash_id)} key={index}>
                                    <td>{el.auction_name}</td>
                                    <td>{el.location}</td>
                                    <td>Bank</td>
                                    <td>{(el.inventory)?el.inventory+' cars': 0}</td>
                                    <td>
                                        <span className="time-left">
                                            <i className="ic-access_timequery_builderschedule"></i> {datetimeFormat(el.start_date_time)}
                                        </span>
                                    </td>
                                </tr>
                            ) 
                           }) : 
                           (!loading) ? <tr><td colSpan='5' style={{"textAlign":'center'}}>Not Record Found</td></tr> : <tr></tr>
                        }                                               

                    </tbody>
                </table>
                <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': listData, totalLengthWithoutPagination, paginationData }} sendRequestDataToParent={handleRequestData} />
            </div>



        );
    // }
}
 
export default React.memo(withRouter(UpcommingAuctionTable));







