import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
// import { v4 as uuidv4 } from 'uuid';
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import Button from './../../elements/Button';
import ModalPopup from '../../elements/Popup';
import KycComment from './KycComment';
import KycAllComment from './KycAllComment';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from './../../../service';
import Q from 'q';
import { withTranslation } from 'react-i18next';
import Accordion from './../../elements/Accordion';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
import moment from 'moment';

class KycDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.addNewKycDetail = this.addNewKycDetail.bind(this);
        this.deleteNewKycDetail = this.deleteNewKycDetail.bind(this);
        this.state = {
            key: 1,
            city_id: "",
            loading: true,
            bank_state_id: "",
            location: "",
            dealership: "",
            business: "",
            filteredcity: props.cityAllList || [],
            kyccategory: [],
            kycdoc: [],
            kycMasterlist: props.kycMasterlist || [],
            dealer_id_hash: "",
            startDate: new Date(),
            kycUploadFile: [],
            kycDealerData: {},
            kycDealerDetail: {},
            kycDealerDocData: [],
            kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }],
            kycDealerCallVerificationData: [],
            basic_details: props.basic_details || {},
            kyc_category_id: '',
            errors: {},
            kyc_errors: [],
            kyc_doc_errors: [],
            kyc_upload_doc_errors: [],
            cv_link_errors: {},
            addNewBankDetail: false,
            bankList:[],
            showCommentMsg:false,
            showAllCommentMsg:false,
            kycMasterlistAddKyc : [{
                id: 1,
                name: "Bank Statement",
                status: 1
            }, {
                id: 2,
                name: "Bank Cover",
                status: 1
            }],
            formSubType:props.formSubType,
            synchedKycDoc:{},
            maxKycDocAllow: true,
            addMoreKycValidation: false,
            addMoreKycValidationMsg: ''
            
        }
    }
    componentDidMount = async () => {
        await this.getDealerKycDetail();
        await this.getDealerDocKycDetail();
        await this.getDealerCallVerificationKycDetail();
        await this.getBankList();
    }

    getBankList = async()=>{
        DealerService.getMasters(["bank_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ bankList: response.data.data.bank_list});
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handlebankListOptionChange = (ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }
    handleStateOptionChange = () => (ovalue) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.kycDealerData.bank_state_id = ovalue.id;
        let citylist = [...this.state.cityAllList];
        const result = (citylist && citylist.filter(cityinfo => cityinfo.state_id == ovalue.id)) || [];
        this.setState({ filteredcity: result })
    }
    handleDocCategoryOptionChange = (idx) => (ovalue) => {
        let kycDealerDoc = [...this.state.kycDealerDocData];
        kycDealerDoc[idx].kyc_master_id = ovalue.id;
        this.setState({ kycDealerDocData: kycDealerDoc })
    }
    // handleChange = date => {//deleteme duplicate function
    //     this.setState({
    //         startDate: date
    //     });
    // };
    handleSelect(key) {
        this.setState({ key });
    }
    handledealertype = (sname, ovalue) => {
        let chkval = ovalue.target.value;
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_account_type = chkval;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }
    handletKycDealerDataTextChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new[sname] = ovalue.target.value;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }
    handletkycDealerDocTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerDocData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerDocData: kycMasterlist_new }, () => {

        })

    }
    handletkycDealerCallVerificationTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerCallVerificationData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerCallVerificationData: kycMasterlist_new }, () => {
        })
    }
    
    handleCityOptionChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_city_id = ovalue.id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }

    addNewKycDetail() {
        this.setState({ addNewBankDetail: true });
    }

    deleteNewKycDetail() {
        this.setState({ addNewBankDetail: false });
    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
 
    setasprimary = (kyc_dealer_data) =>  {
        let { id } = kyc_dealer_data
        var confirmPrimary = window.confirm(this.props.t('dealerDetails.kyc_details.primary_set'));
        if(id > 0 && confirmPrimary == true){
            this.setState({ loading: true });
            DealerService.setBankAsPrimary({kyc_id:id,dealer_id_hash:this.get_dealer_id_hash})
            .then((result) => {
                if (result && result.data && result.data.status == 200) {
                    this.getDealerKycDetail();
                    this.setState({ loading: false });
                    toast.success(result.data.message);
                } else {
                    this.getDealerKycDetail();
                    this.setState({ loading: false });
                    toast.error(result.data.message || 'Some Error Occurred');
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                toast.error(error.message || 'Some Error Occurred');
            });
        }
    }

    
    componentWillReceiveProps(nextProps) {
        this.setState({
            basic_details: nextProps.basic_details,
            cityAllList: nextProps.cityAllList,
            filteredcity: nextProps.cityAllList,
            kycMasterlist: nextProps.kycMasterlist
        });
        let basic_info = this.state.basic_details 
        let kycdoc = basic_info.dealer_documents ?? [];
        kycdoc = kycdoc.filter((ele) => [2,20].includes(ele.document_id))
        const filterDoc = kycdoc.reduce((acc, item) => {
            const { document_id, doc_url, signed_url } = item;
            if (!acc[document_id]) {
                acc[document_id] = { doc_url: [], signed_url: [] };
            }
            acc[document_id].doc_url.push(...doc_url);
            acc[document_id].signed_url.push(...signed_url);
            return acc;
        }, {});
        this.setState({
            synchedKycDoc:filterDoc 
        })
    }
    stringifyFormData(fd) {
        var object = {};
        fd.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.parse(JSON.stringify(object));
    }

    checkAddKycValidationOnChange = (dealerKycDocRow, kyc_child_id) => {
        let status = true;
        const result = dealerKycDocRow.filter(item => item.kyc_master_id === 1 || item.kyc_master_id === 2)
                   .map(item => item.kyc_child_id);
        if(result.includes(kyc_child_id)){
            status = false;
            this.setState({addMoreKycValidationMsg: 'Kyc Category already exist, please update there.', addMoreKycValidation:true});
        }

        return status;
        
    }

    handleKycDocChange = (idx) => (ovalue) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        const validate = this.checkAddKycValidationOnChange(kycDealerDocData_new, ovalue.kyc_child_id);
        if(validate){
        kycDealerDocData_new[idx].kyc_child_id = ovalue.kyc_child_id;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
        }
    }

    getDealerKycDetail = async () => {
        DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = [];
                let address1 = '';
                let address2 = '';
                if (result.data && result.data.data && result.data.data.length > 0) {
                    tempkycDealerData = result.data.data
                    for (let i = 0; i < tempkycDealerData.length; i++) {
                        if (tempkycDealerData.bank_address != '') {
                            address1 = tempkycDealerData[i].bank_address.split('|')[0];
                            address2 = tempkycDealerData[i].bank_address.split('|')[1];
                        }
                        tempkycDealerData[i].address1 = address1;
                        tempkycDealerData[i].address2 = address2;
                    }
                   
                }
                this.setState({ kycDealerData: tempkycDealerData, kycDealerDetail: tempkycDealerData , loading: false })
            }
            else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }
    getDealerDocKycDetail = async () => {
        DealerService.getDocKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerDocData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    getDealerCallVerificationKycDetail = async () => {
        DealerService.kycDealerCallVerificationData(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerCallVerificationData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    
    s3UploadFiles = (allfiles, formPostdata) => {
        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", this.state.basic_details.id);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });
            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                // let temp_kyc_master_id = 0;//deleteme not in use
                                let dealerKycDocSaveJson = {};
                                if (formPostdata.kyccategory.length > 1) {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory[index],
                                        "kyc_child_id": formPostdata.kycdoc[index],
                                        "doc_number": (formPostdata.docnumber[index]) ? (formPostdata.docnumber[index]) : '',
                                        "comment": formPostdata.Comment[index] ? formPostdata.Comment[index] : ''
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc[index] != null && formPostdata.dc_dealer_kyc_doc[index] > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc[index];
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];

                                    }
                                } else {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory,
                                        "kyc_child_id": formPostdata.kycdoc,
                                        "doc_number": (formPostdata.docnumber) ? formPostdata.docnumber : '',
                                        "comment": (formPostdata.Comment) ? formPostdata.Comment : ''
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                }

                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                                let type = dealerKycDocSaveJson.kyc_child_id ?? 0;
                                if(type){
                                    this.updateSyncChekdKycDoc(type,tempFileUrl)
                                }

                                
                            }
                        }
                    });
                    if(this.state.maxKycDocAllow){
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ loading: false })
                            toast.success(result.data.message, {
                                // onClose: () => window.location.reload(true)
                            });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });

                    //change object in document upload format that supports in api
                    const getifDocuments = Object.keys(this.state.synchedKycDoc).map(key => ({
                        id: Number(key),
                        doc_url: this.state.synchedKycDoc[key].doc_url
                    }));
                    let ifPostData = {
                        dealer_id: this.state.basic_details.id,
                        documents: getifDocuments
                    };
                    DealerService.saveDiffDocDetails(ifPostData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ loading: false })
                            toast.success(result.data.message, {
                                onClose: () => window.location.reload(true)
                            });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                }else{
                    this.setState({ loading: false })
                }
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }
    s3UploadBankDetailFiles = (allfiles, formPostdata ,kyc_id) => {
        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();
                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", this.state.basic_details.id);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });
            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                // let temp_kyc_master_id = 0;//deleteme not in use
                                let dealerKycDocSaveJson = {};
                                if (formPostdata.add_kyc_category.length > 1) {
                                    if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];
                                        dealerKycDocSaveJson.kyc_id = kyc_id;
                                        dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                    }
                                } else {
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                        dealerKycDocSaveJson.kyc_id = kyc_id;
                                        dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                    dealerKycDocSaveJson.kyc_id = kyc_id;
                                    dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];

                                }
                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                            }
                        }
                    });
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycBankDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ addNewBankDetail: false, loading: false });
                            this.getDealerKycDetail();
                            toast.success(result.data.message);
                            // toast.success(result.data.message, {
                            //     onClose: () => window.location.reload(true)
                            // });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }
    saveBankDetail = (event) => {
        event.preventDefault();
        // var parentThis = this;//delete me not in use
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
           if (this.validFormData(formdata)) {
            this.setState({ loading: true });
            var r = window.confirm(this.props.t('dealerDetails.kyc_details.warning_alert_msg'));
            if (r==true) {
                let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
                let dealerKycSaveJson = {
                    "dealer_id_hash": this.get_dealer_id_hash,
                    "bank_id": formdata.bankname,
                    "bank_address": formdata.bankaddress1 + " | " + formdata.bankaddress2,
                    "bank_state_id": formdata.state_id || 0,
                    "bank_city_id": formdata.city_id || 0,
                    "bank_pincode": formdata.pincode || 0,
                    "bank_account_type": formdata.account_type,
                    "bank_account_no": formdata.acno,
                    "branch_name": formdata.branch_name,
                    "beneficiary_name": formdata.beneficiary_name,
                    "bank_micr_code": formdata.micrcode
                    // "bank_ifsc_code": formdata.ifsccode
                };
                DealerService.saveKycDetails(dealerKycSaveJson).then(result => {
                    if (result && result.data && result.data.status == 200) {
                        this.s3UploadBankDetailFiles(allfiles, formdata , result.data.data.id);
                        this.setState({kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }]})
                        // this.getDealerKycDetail();
                        // toast.success(result.data.message);
                    }
                    else {
                        this.setState({ loading: false })
                        toast.error(result.data.message || "Some Error Occurred");
                    }
                }).catch(error => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
            }else{
                this.setState({ loading: false }) 
            }
         }
    }
    saveKycDetail = (event) => {
        event.preventDefault();
        // var parentThis = this;//deleteme not in use
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var kyc_errors = [...this.state.kyc_errors];
        var error = {};
        Object.keys(formdata).forEach((key) => {
            if (key == 'kyccategory' || key == 'kycdoc') {
                let key_error_msg = '';
                if (key == 'kyccategory') {
                    key_error_msg = 'key category';
                } else if (key == 'kycdoc') {
                    key_error_msg = 'key doc';
                }
                if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
                    formdata[key].forEach((fileskyc, index) => {
                        let value = formdata[key][index];
                        if (!value) {
                            error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                            kyc_errors[index] = error;
                        }
                    })
                } else {
                    let value = formdata[key];
                    if (!value) {
                        error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                        kyc_errors[0] = error;
                    }
                }
            }

        })
        this.setState({ kyc_errors: kyc_errors }, () => { })
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        if (formIsValid) {
            let allfiles = document.querySelectorAll('input[type="file"]');
            this.s3UploadFiles(allfiles, formdata);
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }

    }
    saveKycCallVerificationDetail = (event) => {
        event.preventDefault();
        // var parentThis = this;//deleteme not in use
        let dealerKycCallVerificationSaveArr = [];
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var error = {}; 
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        let dealerKycDocSaveJson = {};
        if (formIsValid) {
            if (Array.isArray(formdata.comment)) {
                for (let index = 0; index < formdata.comment.length; index++) {
                    dealerKycDocSaveJson = {
                        "comment": formdata.comment[index],
                        "link": formdata.link[index]
                    }
                    if (formdata.dc_dealer_kyc_call_verification_id[index] != null && formdata.dc_dealer_kyc_call_verification_id[index] > 0) {
                        let id = formdata.dc_dealer_kyc_call_verification_id[index];
                        dealerKycDocSaveJson.id = id;
                    }
                    // if (!dealerKycDocSaveJson.link.trim()) error[index + "_link"] = "required";
                    dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
                }
            } else {     
                dealerKycDocSaveJson = {
                    "comment": formdata.comment,
                    "link": formdata.link
                }            
                if (formdata.dc_dealer_kyc_call_verification_id!='') {
                    let id = formdata.dc_dealer_kyc_call_verification_id;
                    dealerKycDocSaveJson.id = id;
                }
                // if (!dealerKycDocSaveJson.link.trim()) error["0_link"] = "required";
                dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
            }
            let postData = {
                dealer_id_hash:this.get_dealer_id_hash,
                docs: dealerKycCallVerificationSaveArr
            };
            // if(Object.keys(error).length > 0) {
            //     this.setState({ cv_link_errors: error }, () => { });
            //     toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
            //     return;
            // }
            // this.setState({ cv_link_errors: error }, () => { });

            DealerService.saveKycCallVerificationDetails(postData).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message, {
                        onClose: () => window.location.reload(true)
                    });
                }
                else {
                    this.setState({ loading: false })
                    toast.error(result.data.message || "Some Error Occurred");
                }
            }).catch(error => {
                this.setState({ loading: false })
                toast.error(error.message || "Some Error Occurred");
            });
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }
    }

    deleteKycDocRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerDocData: dealerKycDocRow });
    }
    deleteKycDocRowCategory = (idx) => {
        let dealerKycDocRowData = [...this.state.kycDealerAddDoc];
        let kyc_doc_errors_msg = [...this.state.kyc_doc_errors];
        let kyc_upload_doc_errors_msg = [...this.state.kyc_upload_doc_errors];
        dealerKycDocRowData.splice(idx, 1);
        kyc_doc_errors_msg.splice(idx, 1);
        kyc_upload_doc_errors_msg.splice(idx, 1);
        this.setState({ kycDealerAddDoc: dealerKycDocRowData ,kyc_doc_errors: kyc_doc_errors_msg ,kyc_upload_doc_errors: kyc_upload_doc_errors_msg});
    }
    deleteKycCallVerificationRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow });
    }
    removecurrentdoc = (idx) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].file_url = null;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }
     removeSyncChekdKycDoc = (idx, type) => {
        let getSynchedKycDoc = this.state.synchedKycDoc
        
        //removed the images for different type. type: tin/dl , idx:index of image to remove from list
        if (getSynchedKycDoc[type]) {
            // Check if the doc_url array has more than idx elements
            if (getSynchedKycDoc[type].doc_url.length > idx) {
                // Remove the element at index idx
                getSynchedKycDoc[type].doc_url.splice(idx, 1);
            }
            // Check if the signed_url array has more than idx elements
            if (getSynchedKycDoc[type].signed_url.length > idx) {
                // Remove the element at index idx
                getSynchedKycDoc[type].signed_url.splice(idx, 1);
            }
        }

        // getSynchedKycDoc = this.cleanObject(getSynchedKycDoc);
        this.setState({
            synchedKycDoc: getSynchedKycDoc
        })
    }

    cleanObject = (obj) => {
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key] = obj[key].filter(item => item !== null && item !== undefined);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                this.cleanObject(obj[key]);
            }
        }
    }

    updateSyncChekdKycDoc = (type, file_url) => {
        let doc_type = {'1':'2','2':'20'}
        let getSynchedKycDoc = this.state.synchedKycDoc ?? {}

        if (doc_type[type] && file_url) {
            const docTypeKey = doc_type[type];
            if (!getSynchedKycDoc[docTypeKey]) {
                getSynchedKycDoc[docTypeKey] = { doc_url: [] };
            }
            
            if (Array.isArray(getSynchedKycDoc[docTypeKey].doc_url)) {
                if(getSynchedKycDoc[docTypeKey].doc_url.length >3){
                this.setState({maxKycDocAllow:false})
                toast.error("Maximum 4 Documents Allowd");
                }else{
                getSynchedKycDoc[docTypeKey].doc_url.push(file_url);
                }
            } else {
                getSynchedKycDoc[docTypeKey].doc_url = [file_url];
            }
        }
    }
    checkAddKycValidation = (dealerKycDocRow) => {
        let status = true;
        const result = dealerKycDocRow.filter(item => item.kyc_master_id === 1 || item.kyc_master_id === 2)
                   .map(item => item.kyc_child_id);
        if(result.includes(1) && result.includes(2)){
            status = false;
            this.setState({addMoreKycValidationMsg: 'Kyc Category already exist, please add there.', addMoreKycValidation:true});
        }

        return status;
        
    }
    addKycRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        const isValidate = this.checkAddKycValidation(dealerKycDocRow);
        if(isValidate){
        let newDealerKycPush = { 'file_url': '' };
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerDocData: dealerKycDocRow })
        }
    }
    addKycRowCategory = () => {
        let dealerKycDocRow = [...this.state.kycDealerAddDoc];
        let newDealerKycPush = { 'file_url': '' };
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerAddDoc: dealerKycDocRow })
    }

    handleKycDocCategoryOptionChange = (idx) => (ovalue) => {
        let kycDealerDoc = [...this.state.kycDealerAddDoc];
        kycDealerDoc[idx].kyc_category_id = ovalue.id;
        this.setState({ kycDealerAddDoc: kycDealerDoc })
    }
    addKycCallVerificationRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        let newDealerKycPush = {};
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow })
    }
    validFormData = (formData) => {
        let errors = {};
        let formIsValid = true;
        var kyc_doc_errors = [...this.state.kyc_doc_errors];
        var kyc_upload_doc_errors = this.state.kyc_upload_doc_errors;
        if (!formData['bankname']) {
            formIsValid = false;
            errors["bankname"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
        }
        if (!formData['branch_name']) {
            formIsValid = false;
            errors["branch_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_required');
        }
        if (!formData['beneficiary_name']) {
            formIsValid = false;
            errors["beneficiary_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_required');
        }
        if (!formData['acno']) {
            formIsValid = false;
            errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
        }
        let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
        Object.keys(formData).forEach((key) => {
            if (key == 'add_kyc_category') {
                let key_error_msg = '';
                let key_error_msg_new = 'Please upload DocFile';
                if (key == 'add_kyc_category') {
                    key_error_msg = 'kyc category';
                }
                if (Array.isArray(formData[key]) && formData[key].length > 0) {
                    formData[key].forEach((fileskyc, index) => {
                        let value = formData[key][index];
                        if (!value) {
                            errors[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                            kyc_doc_errors[index] = errors;
                        }else{
                            delete kyc_doc_errors[index];
                        }
                        if (allfiles[index].files.length == 0) {
                            kyc_upload_doc_errors[index] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };
                        } else {
                            delete kyc_upload_doc_errors[index];
                        }
                    })
                }
                else {
                    kyc_upload_doc_errors[0] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };;
                }
            }

        })
        if(allfiles[0].files.length>0){
            delete kyc_upload_doc_errors[0];
        }
        this.setState({ kyc_doc_errors: kyc_doc_errors }, () => { })
        if ((Object.keys(errors).length) == 0 && (Object.keys(kyc_upload_doc_errors).length) == 0) {
            formIsValid = true;
            this.setState({ kyc_doc_errors: [], kyc_upload_doc_errors: [] })
        } else {
            formIsValid = false;
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    handleChangeForFlip = (type, index, kycId) => async ({ target }) => {
        let kycDealerDetail = [...this.state.kycDealerDetail];
        let status = ''
        if (target.checked) {
           kycDealerDetail[index]['active_status'] = '1';
           kycDealerDetail[index]['id'] = kycId;
            status = '1';
        } else {
            kycDealerDetail[index]['active_status'] = '0';
            kycDealerDetail[index]['id'] = kycId;
            status = '0';
        }
        this.setState({ kycDealerDetail });
        this.setState({ [type]: true, kycId: kycId, currentIndex: index, active_status: status });
    }

  

    handleChangeForViewComment = (type, index, kyc_comment_data) => {
        let kycDealerDetail = [...this.state.kycDealerDetail];
        this.setState({ kycDealerDetail });
        this.setState({ [type]: true, currentIndex: index, comment: kyc_comment_data });
    }
    
    closeModal = (flag=true) => {
        if (flag !== false) {
            let index = this.state.currentIndex;
            let kycDealerDetail = [...this.state.kycDealerDetail];
            if (kycDealerDetail[index]['active_status'] == '1') {
                kycDealerDetail[index]['active_status'] = '0';
            } else {
                kycDealerDetail[index]['active_status'] = '1';
            }
            this.setState({ kycDealerDetail });
        }
        this.setState({ showCommentMsg: false ,showAllCommentMsg: false});
    }

    closeModalViewAllComment = () => {
        this.setState({ showCommentMsg: false ,showAllCommentMsg: false});
    }
    

    onChangeHandler = event => {
        let files = event.target.files
        let err = [] // create empty array
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf','application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (let x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';
                // assign message to array
            }
            if(files[x].size && files[x].size > 8000000){
                err[x] = this.props.t('dealerDetails.kyc_details.kyc_file_size_error') + ' \n';
            }
        };
        for (var z = 0; z < err.length; z++) { // loop create toast massage
            event.target.value = null
            toast.error(err[z])
        }
        return true;
    }
    approveDealer = async () => {
        var r = window.confirm(this.props.t('dealerDetails.kyc_details.approve_prompt'));

        if (r === true) {
            const formSubType = this.props.formSubType;
            let redirect_url = '';

            if (formSubType.indexOf("basic_detail") === -1) {
                redirect_url = `/dealer/${this.get_dealer_id_hash}/basic-details`
            } else if (formSubType.indexOf("outlet_info") === -1) {
                redirect_url = `/dealer/${this.get_dealer_id_hash}/outlet-info`
            }
            if (redirect_url!=='') {
                this.props.history.push(redirect_url);
                if (typeof this.props.onClick === 'function') {
                    this.props.onClick();
                }
            }


            if (redirect_url === '') {
                this.setState({
                    loading: true,
                });
                let dealerUpdateData = {}
                dealerUpdateData['status'] = '1';
                dealerUpdateData['current_status'] = this.state.basic_details.status;
                dealerUpdateData['dealer_id_hash'] = this.state.basic_details.dealer_id_hash;
                let response = await DealerService.dealerStatusChange(dealerUpdateData);
                if (response.data.status === 200) {
                    toast.success(this.props.t('dealerDetails.kyc_details.Dealer_approved'));
                    let basicDetails = this.state.basic_details
                    basicDetails.status = '1';
                    this.setState({
                        basic_details: basicDetails,
                    });
                } else {
                    toast.error(this.props.t('dealerDetails.kyc_details.dealer_activation_fail'));
                }
                this.setState({
                    loading: false,
                });
            }
        }
    }
    render() {
        const bankList = this.state.bankList;
        const { StateList, auction_buyer_id } = this.props;
        const { kycMasterlistAddKyc ,kycDealerAddDoc, addNewBankDetail, filteredcity, kycMasterlist, kycDealerData, kycDealerDetail, kycDealerDocData, kycDealerCallVerificationData, errors, kyc_errors ,kyc_upload_doc_errors,kyc_doc_errors} = this.state;
        const { cv_link_errors } = this.state;
        let basicinfo = this.state.basic_details;
        let kycDocuments = basicinfo.dealer_documents ?? [];
        let tinDocuments = kycDocuments.filter((ele) => ele.document_id === 2);
        let dlDocuments = kycDocuments.filter((ele) => ele.document_id === 20);   
        // let dealerTypeIds = basicinfo.dealer_type_ids;//deleteme not in use
        let auction_buyer_subscribed = (basicinfo.subscription)?basicinfo.subscription['auction_buyer._is_active']:0;         
        let masterlist = {};
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                masterlist['cat_' + val.id] = val.child;
            })
        }
        
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        return (
            <div className={(this.state.loading ? "card loading" : "card")}>
                <div className="card-heading">
                    <div className="pull-left">
                        <h1 className="card-title">
                            {this.props.t('dealerDetails.kyc_details.kyc_details_label')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span>
                        </h1>
                    </div>
                    <div className="pull-right">
                        <button className="btn btn-success" onClick={this.addNewKycDetail}>{this.props.t('dealerDetails.kyc_details.add_more_bank_detail')}</button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body">    
                    <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                        <div className="accrdian-bt0">
                            {addNewBankDetail ?
                                <Accordion title={this.props.t('dealerDetails.kyc_details.add_more_bank_detail')} active="true" delete="true">
                                    <button className="btn btn-link delete-user" onClick={() => { if (window.confirm(this.props.t('dealerDetails.Basic_Employee_Details.Confirm_Delete'))) this.deleteNewKycDetail() }} ><i className="ic-delete"></i>{this.props.t('dealerDetails.Basic_Employee_Details.Delete')}</button>

                                    <div className="card-heading">
                                        <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={this.saveBankDetail} method="post">
                                            <div className="row">
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        id="bankname"
                                                        onChange={this.handlebankListOptionChange}
                                                        options={bankList}
                                                        name="bankname"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                        value={bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ bank_id }) => bank_id}
                                                    />
                                                    {errors.bankname &&
                                                        <div className="error show" >{errors.bankname}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.branch_name) ? errors.branch_name : ''} name="branch_name" id="branch_name" placeholder={this.props.t('dealerDetails.kyc_details.branch_name')} value={kycDealerData.branch_name} label={this.props.t('dealerDetails.kyc_details.branch_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'branch_name')} required={true} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.bankaddress1) ? errors.bankaddress1 : ''} name="bankaddress1" id="bankaddress1" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line1')} value={kycDealerData.address1} label={this.props.t('dealerDetails.kyc_details.branch_address_line1')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address1')} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" name="bankaddress2" id="bankaddress2" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line2')} value={kycDealerData.address2} label={this.props.t('dealerDetails.kyc_details.branch_address_line2')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address2')} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.state')}</label>
                                                    <Select
                                                        id="state_id"
                                                        onChange={this.handleStateOptionChange()}
                                                        options={StateList}
                                                        name="state_id"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.state')}
                                                        value={StateList.filter(({ id }) => id == kycDealerData.bank_state_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                    />
                                                    {errors.state_id &&
                                                        <div className="error show" >{errors.state_id}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.location_of_bank')}</label>
                                                    <Select
                                                        id="city_id"
                                                        options={filteredcity}
                                                        name="city_id"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.location_of_bank')}
                                                        value={filteredcity.filter(({ id }) => id === kycDealerData.bank_city_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                                                    />
                                                    {errors.city_id &&
                                                        <div className="error show" >{errors.city_id}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="tel" error={(errors.pincode) ? errors.pincode : ''} name="pincode" id="pincode" placeholder="Pin Code" value={kycDealerData.bank_pincode} label={this.props.t('dealerDetails.kyc_details.pincode')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_pincode')} />
                                                </div>
                                                <div className="col-sm-4 form-group rc-inline">
                                                    <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                    <RadioBox type="radio" name="account_type" id="saving" value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kycDealerData.bank_account_type == 'saving') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                    <RadioBox type="radio" name="account_type" id="current" value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kycDealerData.bank_account_type == 'current') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                    {errors.account_type &&
                                                        <div className="error show" >{errors.account_type}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <InputField type="tel" error={(errors.acno) ? errors.acno : ''} name="acno" id="acno" placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kycDealerData.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_account_no')} required={true} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.micrcode) ? errors.micrcode : ''} name="micrcode" id="micrcode" placeholder={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} value={kycDealerData.bank_micr_code} label={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_micr_code')} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kycDealerData.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'beneficiary_name')} required={true} />
                                                </div>
                                                </div>
                                                <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>

                                            {
                                                (kycDealerAddDoc.length) ?
                                                    kycDealerAddDoc.map((kycDealerDoc, idx) =>
                                                        <div className="row" key={idx}>
                                                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id) ? kycDealerDoc.id : ''} ></input>
                                                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name) ? kycDealerDoc.file_name : ''} ></input>
                                                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url) ? kycDealerDoc.file_url : ''} ></input>

                                                            <div className="col-sm-2">
                                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    id="add_kyc_category"
                                                                    onChange={this.handleKycDocCategoryOptionChange(idx)}
                                                                    options={kycMasterlistAddKyc}
                                                                    name="add_kyc_category"
                                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                                    value={kycMasterlistAddKyc.filter(({ id }) => id === kycDealerDoc.kyc_category_id)}
                                                                    getOptionLabel={({ name }) => name}
                                                                    getOptionValue={({ id }) => id}
                                                                />

                                                                {
                                                                    (kyc_doc_errors && kyc_doc_errors[idx] && kyc_doc_errors[idx].add_kyc_category) ? <span className="error show">{kyc_doc_errors[idx].add_kyc_category}</span> : ''
                                                                }
                                                            </div>
                                                            <div className="col-sm-3 form-field add_bank_file">
                                                                <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                                <input type="file" name="fileName" multiple className={
                                                                    (kycDealerData.file_url != '' && kycDealerData.file_url != null) ?
                                                                        'form-control hide' : 'form-control'
                                                                } onChange={this.onChangeHandler} />
                                                                {
                                                                    (kyc_upload_doc_errors && kyc_upload_doc_errors[idx] && kyc_upload_doc_errors[idx].doc_upload) ? <span className="error show">{kyc_upload_doc_errors[idx].doc_upload}</span> : ''
                                                                }
                                                            </div>

                                                            <div className="col-sm-1 pad-t25">
                                                                {
                                                                    (idx > 0) ?
                                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRowCategory.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button> : ''
                                                                }
                                                            </div>

                                                        </div>
                                                    ) : ''
                                            }
                                            <Button type="button" btnClass="btn btn-link" onClick={this.addKycRowCategory}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                                            <div className="card-footer text-center">
                                                <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                            </div>
                                        </form>
                                    </div>
                                </Accordion>
                                : ''}

                            {
                                kycDealerDetail.length > 0 ?
                                    kycDealerDetail.map((kyc_dealer_data, index) =>
                                        <Accordion key={kyc_dealer_data.id} title={kyc_dealer_data.branch_name+ " - " +kyc_dealer_data.bank_account_no}>
                                            {
                                                kyc_dealer_data.is_primary != '1' ? (
                                                    kyc_dealer_data.active_status == '1' ?(
                                                    <>
                                                    <label className="set_primary btn btn-link">
                                                        <a
                                                           href={() => false}
                                                           onClick={() => {
                                                            this.setasprimary(kyc_dealer_data);
                                                        }}
                                                        >
                                                        <u>Set as Primary</u>
                                                        </a>
                                                    </label>
                                                    </>):''
                                                ) : (
                                                    <label className="set_primary">
                                                    <span style={{ color: '#E46536' }}>Primary</span>
                                                    </label>
                                                )
                                            }
                                            {   
                                                (kyc_dealer_data.active_status == '1' || kyc_dealer_data.active_status == '0') ? <>
                                                
                                                    <label className="delete-user switch-btn btn btn-link" htmlFor={"active" + kyc_dealer_data.id} >                                                    
                                                        <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onChange={this.handleChangeForFlip('showCommentMsg', index, kyc_dealer_data.id)} checked={(kyc_dealer_data.active_status == '1') ? true : false} />
                                                        {/* <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onClick={() => this.handleChangeForFlip('showCommentMsg',kyc_dealer_data.id, index)} checked={(kyc_dealer_data.active_status == '1') ? true : false} /> */}
                                                        <div className="slider round"></div>
                                                        <span className="switch-label"></span>
                                                        {this.props.t('Listing.Search_Result.Active')}
                                                    </label></>
                                                    : ""
                                            }   
                                            
                                            <div className="card-heading">
                                                <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.bank_name')}</label>
                                                        <Select
                                                            id={"bankname" + index}
                                                            options={bankList}
                                                            isDisabled={true}
                                                            name={"bankname" + index}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                            value={bankList.filter(({ bank_id }) => bank_id == kyc_dealer_data.bank_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ bank_id }) => bank_id}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true}  name={'branch_name' + index} id={"branch_name" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_name')} value={kyc_dealer_data.branch_name} label={this.props.t('dealerDetails.kyc_details.branch_name')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"bankaddress1" + index} id={"bankaddress1" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line1')} value={kyc_dealer_data.address1} label={this.props.t('dealerDetails.kyc_details.branch_address_line1')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"bankaddress2" + index} id={"bankaddress2" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line2')} value={kyc_dealer_data.address2} label={this.props.t('dealerDetails.kyc_details.branch_address_line2')} />
                                                    </div>
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.state')}</label>
                                                        <Select
                                                            id={"state_id" + index}
                                                            onChange={this.handleStateOptionChange()}
                                                            isDisabled={true}
                                                            options={StateList}
                                                            name={"state_id" + index}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.state')}
                                                            value={StateList.filter(({ id }) => id == kyc_dealer_data.bank_state_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ id }) => id}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.location_of_bank')}</label>
                                                        <Select
                                                            id={"city_id" + index}
                                                            options={filteredcity}
                                                            name={"city_id" + index}
                                                            isDisabled={true}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.location_of_bank')}
                                                            value={filteredcity.filter(({ id }) => id === kyc_dealer_data.bank_city_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ id }) => id}
                                                            onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="tel" disabled={true} name={"pincode" + index} id={"pincode" + index} placeholder="Pin Code" value={kyc_dealer_data.bank_pincode} label={this.props.t('dealerDetails.kyc_details.pincode')} />
                                                    </div>
                                                    <div className="col-sm-4 form-group rc-inline">
                                                        <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                        <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"saving_"+index} value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kyc_dealer_data.bank_account_type == 'saving') ? true : false} readOnly={true} />
                                                        <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"current"+index} value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kyc_dealer_data.bank_account_type == 'current') ? true : false} readOnly={true} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label>{this.props.t('dealerDetails.kyc_details.added_account_date')}:</label>
                                                        <p>{moment(kyc_dealer_data.created_date).format('YYYY-MM-DD')}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <InputField type="tel" disabled={true} name={"acno" + index} id={"acno" + index} placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kyc_dealer_data.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"micrcode" + index} id={"micrcode" + index} placeholder={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} value={kyc_dealer_data.bank_micr_code} label={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"beneficiary_name" + index} id={"beneficiary_name" + index} placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kyc_dealer_data.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} />
                                                    </div>
                                                </div>
                                                {kyc_dealer_data.kyc_comment.length > 0 ?
                                                    <div className="row">
                                                        <div className="col-sm-6 form-field">
                                                            <label>{this.props.t('dealerDetails.kyc_details.comment')}:</label>                                                            
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <a className="pull-right" href={() => false}
                                                                                title={this.props.t('dealerDetails.kyc_details.view_all_comment')}
                                                                                onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleChangeForViewComment('showAllCommentMsg',index ,kyc_dealer_data.kyc_comment)
                                                                                        }}
                                                                            > {this.props.t('dealerDetails.kyc_details.view_all_comment')} </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.action')}</th>
                                                                        <th style={{width: "60%"}}>{this.props.t('dealerDetails.kyc_details.comment')}</th>
                                                                        <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.comment_date')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: "20%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment_flag == "1"? this.props.t('dealerDetails.kyc_details.active') : this.props.t('dealerDetails.kyc_details.inactive')} </td>
                                                                        <td style={{width: "60%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment}</td>
                                                                        <td style={{width: "20%"}}>{moment(kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].created_date).format('YYYY-MM-DD')}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {
                                                    (kyc_dealer_data.kyc_doc.length) ?
                                                    kyc_dealer_data.kyc_doc.map((doc, idx) =>
                                                    <div key={'kyc-dealer-data-'+idx}>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}</label>
                                                                    <Select
                                                                        id="add_kyc_category"
                                                                        //onChange={this.handleKycDocCategoryOptionChange(idx)}
                                                                        options={kycMasterlistAddKyc}
                                                                        isDisabled={true}
                                                                        name="add_kyc_category"
                                                                        placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                                        value={kycMasterlistAddKyc.filter(({ id }) => id === doc.kyc_category_id)}
                                                                        getOptionLabel={({ name }) => name}
                                                                        getOptionValue={({ id }) => id}
                                                                    />
                                                          
                                                                </div>
                                                                <div className="col-sm-4" >
                                                                    <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                                    {
                                                                        (doc.file_url != '' && doc.file_url != null) ?
                                                                            <div className="document-link">
                                                                                {
                                                                                    (doc.file_url != '' && doc.file_url != null) ?
                                                                                        <a href={doc.file_url} target="_blank" rel="noreferrer" download>
                                                                                            {
                                                                                                (doc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (doc.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (doc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                                            }
                                                                                            View File
                                                                                        </a>
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                        </div>
                                                    </div>
                                                        ) : ''
                                                }

                                            </div>
                                        </Accordion>
                                    ) : ''}
                        </div>
                    </div>
                </div>







                <div className={(this.state.loading ? "card loading" : "card")}>
                    <form onSubmit={this.saveKycDetail} method="post" encType="multipart/form-data">
                        <div className="card-heading">
                            <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.upload_kyc_document')}</h2>
                        </div>
                        <div className="card-body">
                            <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>

                            {
                                (kycDealerDocData.length) ?
                                    kycDealerDocData.map((kycDealerDoc, idx) =>
                                        <div className="row" key={idx}>
                                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id)?kycDealerDoc.id:''} ></input>
                                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name)?kycDealerDoc.file_name:''} ></input>
                                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url)?kycDealerDoc.file_url:''} ></input>
                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    id="kyccategory"
                                                    // value={this.state.kyccategory}//deleteme duplicate props
                                                    onChange={this.handleDocCategoryOptionChange(idx)}
                                                    options={kycMasterlist}
                                                    name="kyccategory"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                    value={kycMasterlist.filter(({ id }) => id === kycDealerDoc.kyc_master_id)}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />
                                                {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kyccategory) ? <span className="error show">{kyc_errors[idx].kyccategory}</span> : ''
                                                }
                                            </div>

                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_document')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    id="kycdoc"
                                                    onChange={this.handleKycDocChange(idx)}
                                                    options={masterlist['cat_' + kycDealerDoc.kyc_master_id]}
                                                    name="kycdoc"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_document')}
                                                    value={(masterlist['cat_' + kycDealerDoc.kyc_master_id]) ? masterlist['cat_' + kycDealerDoc.kyc_master_id].filter(({ kyc_child_id }) => kyc_child_id === kycDealerDoc.kyc_child_id) : ''}
                                                    getOptionLabel={({ kyc_child_name }) => kyc_child_name}
                                                    getOptionValue={({ kyc_child_id }) => kyc_child_id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />
                                                {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kycdoc) ? <span className="error show">{kyc_errors[idx].kycdoc}</span> : ''
                                                }
                                            </div>
                                            <div className="col-sm-2">
                                                <InputField type="text" name="docnumber" id="docnumber" placeholder={this.props.t('dealerDetails.kyc_details.document_number')} value={kycDealerDoc.doc_number} label={this.props.t('dealerDetails.kyc_details.document_number')} onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} />
                                            </div>
                                            <div className="col-sm-2">
                                                <InputField type="text" name="Comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerDoc.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerDocTextChange.bind(this, 'comment', idx)} />
                                            </div>
                                            <div className="col-sm-3" >
                                                <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                {/* <input type="file" name="fileName" multiple className={
                                                    (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                        'form-control hide' : 'form-control'
                                                } onChange={this.onChangeHandler} /> */}
                                                <input type="file" name="fileName" multiple className='form-control test' onChange={this.onChangeHandler} />
                                               {/* {
                                                    (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                        <div className="document-link">

                                                            {
                                                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                                    <a href={kycDealerDoc.file_url} target="_blank" rel="noreferrer" download>
                                                                        {
                                                                            (kycDealerDoc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                        }
                                                                        View File2
                                                        </a>
                                                                    : ''
                                                            }
                                                            <span className="delete-docs" onClick={this.removecurrentdoc.bind(this, idx)}><i className="ic-clearclose"></i></span>
                                                        </div>
                                                        : ''
                                                } */}


                                                {
                                                    kycDealerDoc.kyc_child_id === 1 && this.state.synchedKycDoc[2]?.signed_url && this.state.synchedKycDoc[2].signed_url.length > 0 && (
                                                        this.state.synchedKycDoc[2].signed_url.map((document, index) => {
                                                            return (
                                                                <div className="document-link" key={index}>
                                                                    <a href={document} target="_blank" rel="noreferrer" download>
                                                                        View File
                                                                    </a>
                                                                    <span className="delete-docs" onClick={this.removeSyncChekdKycDoc.bind(this, index, 2)}>
                                                                        <i className="ic-clearclose"></i>
                                                                    </span>
                                                                </div>
                                                            );
                                                        })
                                                    )
                                                }
                                                {
                                                    kycDealerDoc.kyc_child_id === 2 && this.state.synchedKycDoc[20]?.signed_url && this.state.synchedKycDoc[20].signed_url.length>0 && (
                                                        this.state.synchedKycDoc[20].signed_url.map((document,index) => {
                                                    return (
                                                    <div className="document-link">
                                                        <a href={document} target="_blank" rel="noreferrer" download>
                                                        View File
                                                        </a>
                                                        <span className="delete-docs" onClick={this.removeSyncChekdKycDoc.bind(this, index,20)}><i className="ic-clearclose"></i></span>
                                                    </div>
                                                    );
                                                })
                                                )
                                                }

                                            </div>
                                            <div className="col-sm-1 pad-t25">
                                            {
                                                (idx > 3) ?
                                                <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>:''
                                            }
                                            </div>
                                        </div>
                                    ) : ''
                            }
                            {this.state.addMoreKycValidation ? (<div><h5>{this.state.addMoreKycValidationMsg}</h5></div>) : ''}
                            <Button type="button" btnClass="btn btn-link" onClick={this.addKycRow}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                        </div>
                        {
                            (kycDealerDocData.length) ?
                                <div className="card-footer text-center">
                                    <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                </div> : ''
                        }
                    </form>
                </div>
                {
                    (role == 'admin') ?
                        <div className={(this.state.loading ? "card loading" : "card")}>
                            <form onSubmit={this.saveKycCallVerificationDetail} method="post" encType="multipart/form-data">
                                <div className="card-heading">
                                    <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.call_verification')}</h2>
                                </div>
                                <div className="card-body">
                                    {
                                        (kycDealerCallVerificationData.length) ?
                                            kycDealerCallVerificationData.map((kycDealerCallVerification, idx) =>
                                                <div className="row mrg-b15" key={idx} style={{ borderBottom: kycDealerCallVerificationData.length > idx + 1 ? "1px dashed #ccc" : "" }}>
                                                    <input type="hidden" name="dc_dealer_kyc_call_verification_id" id="dc_dealer_kyc_call_verification_id" value={(kycDealerCallVerification.id) ? kycDealerCallVerification.id : ''} ></input>
                                                    <div className="col-sm-4">
                                                        <InputField error={(cv_link_errors[idx + "_link"]) ? cv_link_errors[idx + "_link"] : ''} type="text" name="link" id="link" placeholder={this.props.t('dealerDetails.kyc_details.link')} value={kycDealerCallVerification.link} label={this.props.t('dealerDetails.kyc_details.link')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'link', idx)} />
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.comment')}</label>
                                                        <textarea onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} rows='2' className="form-control" name="comment" value={kycDealerCallVerification.comment}></textarea>
                                                        {/* <InputField type="text" name="comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerCallVerification.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} /> */}
                                                    </div>
                                                    <div className="col-sm-1 pad-t25">
                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycCallVerificationRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>
                                                    </div>
                                                </div>
                                            ) : ''
                                    }
                                    <Button type="button" btnClass="btn btn-link" onClick={this.addKycCallVerificationRow}> {this.props.t('dealerDetails.kyc_details.add_more_call_verification')}</Button>
                                </div>
                                {
                                    (kycDealerCallVerificationData.length > 0) ?
                                        <div className="card-footer text-center">
                                            <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                        </div> : ''
                                }
                            </form>
                        </div>
                        : ''
                }
                <div className="card">
                    <div className="card-footer text-center">
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30"> <i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')} </NavLink> 

                        {(auction_buyer_subscribed == '1' || auction_buyer_id == 36) ?                    
                        <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                        : ['3', '4', '6'].includes(this.state.basic_details.status) ?
                        <button className="btn btn-approve mrg-30" onClick={this.approveDealer.bind(this, 'approve')}>
                            {this.props.t('dealerDetails.kyc_details.approve')}
                        </button> : ""
                         
                        }
                        
                    </div>
                </div>
                {this.state.showCommentMsg == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.add_comment')} modalClose={this.closeModal}>
                        <KycComment getDealerKycDetail={this.getDealerKycDetail} modalClose={this.closeModal} kycId={this.state.kycId} currentIndex={this.state.currentIndex} active_status={this.state.active_status} />
                    </ModalPopup>
                }
                {this.state.showAllCommentMsg == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.all_comment')} modalClose={this.closeModalViewAllComment}>
                        <KycAllComment modalClose={this.closeModalViewAllComment} currentIndex={this.state.currentIndex} comment={[...this.state.comment]} />
                    </ModalPopup>
                }
                
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(withRouter(KycDetails)));
//export default withTranslation('dealers')(BasicDetails)