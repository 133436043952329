import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import LiveAuctionTable from './LiveAuction'
import UpcommingAuctionTable from './UpcommingAuction';
import PurchaseHistory from './PurchaseHistory'
import AuctionFilterCard from './AuctionFilterCards'
import { withTranslation } from 'react-i18next';
import { AuctionService } from './../../../service';
import FullPageLoader from '../Common/FullPageLoader';


class AuctionTabCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveAuctions:{},
            upcommingAuctions:{},
            mybids:'',
            wins:'',
            purchased:'',
            watchlist:'',
            live_count:0,
            upcomming_count:0,
            bid_count:0,
            win_count:0,
            purchase_count:0,
            watch_list_count:0,
            updateInventoryList: false,
            activeTab: 'liveauction',
            startTimeOrder: 'asc',
            pageList:{
                mybid:1,
                wins:1,
                purchased:1,
                watchlist:1
            }
        }
    }
    componentDidMount = async () => {
        this._isMounted = true;
       // this.handleClick();
        const { childRef } = this.props;
        childRef(this);

        this.getBuyerAuctionList({startTimeOrder:this.props.order});
    }

    componentWillUnmount() {
        this._isMounted = false
        const { childRef } = this.props;
        childRef(undefined);
    }

    handleSelect = (key,filters=null,watchDetail=null,loadMore=false) => {
        filters =  (filters)? filters : {startTimeOrder:this.props.order};
        if (key === 'liveauction') {
            this.getBuyerAuctionList(filters);
        } else if (key === 'upcomingauction') {
            this.getBuyerUpcommingAuctions(filters);
        } else if (key === 'mybid') {
            this.getBuyerBidList(loadMore,watchDetail);
        } else if (key === 'wins') {
            this.getBuyerWinList(loadMore,watchDetail);
        } else if (key === 'purchased') {
            this.getBuyerPurchasedList(loadMore,watchDetail);
        } else if (key === 'watchlist') {
            this.getBuyerWatchedList(loadMore,watchDetail);
        }

        this.setState({activeTab: key}) 
        this.props.onChangeValue({tabName: key})
    }

    getBuyerAuctionList=(params=null)=>{        
        this.setState({loading:true})
        if(!params){
            params = {startTimeOrder: this.props.order || "asc"}
        }
        if(params && !params['startTimeOrder']){
            params = {...params, startTimeOrder: "asc"}
        }

        AuctionService.getLiveAuctionsByBuyerId({buyer_id:this.props.buyer_id, page:1, ...params}).then((response) => {
            if (response && response.status === 200 && response.data.status === 200) { 
                let result = response.data.data;   
                let countData =   result.pagination.allcount;          
                this.setState(Object.assign(countData, {liveAuctions:result, loading: false}));
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }

    getBuyerUpcommingAuctions=(params=null)=>{
        this.setState({loading:true})
        if(!params){
            params = {startTimeOrder: this.props.order || "asc"}
        } 
        AuctionService.getUpcommingAuctionsByBuyerId({buyer_id:this.props.buyer_id, page:1, ...params}).then((response) => {
            if (response && response.status === 200 && response.data.status === 200) {  
                let result = response.data.data;   
                let countData =   result.pagination.allcount;               
                this.setState(Object.assign(countData, { upcommingAuctions: result, loading: false}));
            }
        }).catch((error) => {
            this.setState({loading:false})
        }); 
    }

    getBuyerBidList=(loadMore=false,watchDetail=null)=>{
        this.setState({loading:true})
        let page = (loadMore)? this.state.pageList.mybid:1;
        if(watchDetail){  
            if(typeof this.state.mybids =='object'){
                let mybids = this.state.mybids.map((ob) => {
                    return {
                        ...ob,
                        watchlist_status: (+ob.car_id === +watchDetail.car_id)? (watchDetail.type === 'add')?1:0 : ob.watchlist_status
                    }
                });  
                let count = (watchDetail.type === 'add') ? this.state.watch_list_count +1:this.state.watch_list_count-1;
                this.setState({mybids: mybids,loading:false,updateInventoryList: true ,watch_list_count : count});
            }    
        }else{
            AuctionService.getMybidAuctionsByBuyerId({buyer_id:this.props.buyer_id,page:page}).then((response) => {
                if (response && response.status === 200 && response.data.status === 200) { 
                    let result = response.data.data;   
                    let countData =   result.pagination.allcount; 
                    let finalResult = result.data;   
                    if(typeof this.state.mybids =='object' && page >1){
                        finalResult = this.state.mybids.concat(result.data); 
                    }          
                    this.setState(Object.assign(countData,{ mybids: finalResult, loading: false, updateInventoryList: true,pageList:{ ...this.state.pageList, mybid: page +1 }}));
                }
            }).catch((error) => {
                this.setState({loading:false})
            }); 
        }
    }

    getBuyerWinList=(loadMore=false,watchDetail=null)=>{
        this.setState({loading:true})
        let page = (loadMore)? this.state.pageList.wins:1;
        if(watchDetail){  
            if(typeof this.state.wins =='object'){
                let wins = this.state.wins.map((ob) => {
                    return {
                        ...ob,
                        watchlist_status: (+ob.vid === +watchDetail.car_id)? (watchDetail.type === 'add')?1:0 : ob.watchlist_status
                    }
                });  
                let count = (watchDetail.type === 'add') ? this.state.watch_list_count +1:this.state.watch_list_count-1;
                this.setState({wins: wins,loading:false,updateInventoryList: true ,watch_list_count : count});
            }    
        }else{
            AuctionService.getWinAuctionsByBuyerId({buyer_id:this.props.buyer_id,page:page}).then((response) => {
                if (response && response.status === 200 && response.data.status === 200) {
                    let result = response.data.data;   
                    let countData =   result.pagination.allcount;   
                    let finalResult = result.data;   
                    if(typeof this.state.wins =='object' && page >1){
                        finalResult = this.state.wins.concat(result.data); 
                    }          
                    this.setState(Object.assign(countData,{ wins: finalResult, loading: false, updateInventoryList: true,pageList:{ ...this.state.pageList, wins: page +1 }}));
                }
            }).catch((error) => {
                this.setState({loading:false})
            }); 
        }
    }

    getBuyerPurchasedList=(loadMore=false,watchDetail=null)=>{
        this.setState({loading:true})
        let page = (loadMore)? this.state.pageList.purchased :1;
        if(watchDetail){  
            if(typeof this.state.purchased =='object'){
                let purchased = this.state.purchased.map((ob) => {
                    return {
                        ...ob,
                        watchlist_status: (+ob.vid === +watchDetail.car_id)? (watchDetail.type === 'add')?1:0 : ob.watchlist_status
                    }
                });  
                let count = (watchDetail.type === 'add') ? this.state.watch_list_count +1:this.state.watch_list_count-1;
                this.setState({purchased: purchased,loading:false,updateInventoryList: true ,watch_list_count : count});
            }    
        }else{
            AuctionService.getPurchasedAuctionsByBuyerId({buyer_id:this.props.buyer_id,page:page}).then((response) => {
                if (response && response.status === 200 && response.data.status === 200) {   
                    let result = response.data.data;   
                    let countData =   result.pagination.allcount;   
                    let finalResult = result.data;   
                    if(typeof this.state.purchased =='object' && page >1){
                        finalResult = this.state.purchased.concat(result.data); 
                    }          
                    this.setState(Object.assign(countData,{ purchased: finalResult, loading: false, updateInventoryList: true,pageList:{ ...this.state.pageList, purchased: page +1 }}));
                }
            }).catch((error) => {
                this.setState({loading:false})
            }); 
        }
    }

    getBuyerWatchedList=(loadMore=false,watchDetail=null)=>{
        this.setState({loading:true}) 
        let page = (loadMore)?this.state.pageList.watchlist:1;
        if(watchDetail && watchDetail.type === 'remove'){
            let watchListState = this.state.watchlist;
            if(typeof this.state.watchlist === 'object'){
                watchListState = watchListState.filter((ob) => (ob.car_id !== watchDetail.car_id));  
            }   
            this.setState({watchlist: watchListState,loading:false,updateInventoryList: true ,watch_list_count : this.state.watch_list_count -1}); 
        }else{
            AuctionService.getWatchlistAuctionsByBuyerId({buyer_id:this.props.buyer_id,page:page}).then((response) => {
                if (response && response.status === 200 && response.data.status === 200) {     
                    let result = response.data.data;   
                    let countData =   result.pagination.allcount; 
                    let finalResult = result.data;     
                   
                    if(typeof this.state.watchlist =='object' && page >1){  
                        finalResult = this.state.watchlist.concat(result.data);   
                    }          
                    this.setState(Object.assign(countData,{ watchlist: finalResult, loading: false, updateInventoryList: true,pageList:{ ...this.state.pageList, watchlist: page +1 }})); 
                }
            }).catch((error) => {
                this.setState({loading:false})
            }); 
        }  
        
    }

    updateComponentState = (params) => {
        this.setState(params)
    }
   
    updateListByFilters = (filters) => {
        this.handleSelect(this.state.activeTab, filters)
    }

    render() {
        let { loading, liveAuctions, upcommingAuctions, mybids, wins, purchased, watchlist, live_count, upcomming_count, bid_count, win_count,purchase_count, watch_list_count, updateInventoryList, activeTab} = this.state;   
        return (
            <>
                <FullPageLoader show={loading} />

                <div className={"tabs-list"}>

                    <Tabs defaultActiveKey="liveauction" id="uncontrolled-tab-example" className="mb-3" onSelect={(k) => this.handleSelect(k)}>
                        <Tab eventKey="liveauction" title={"Live Auction ("+live_count+")"}>
                            {
                                (activeTab === 'liveauction')
                                ?
                                    <LiveAuctionTable listData={liveAuctions.data} loading={loading} paginationData={liveAuctions.pagination} sendRequestDataToParent={this.getBuyerAuctionList}/>
                                :
                                    ''
                            }
                        </Tab>
                        <Tab eventKey="upcomingauction" title={"Upcoming Auction ("+upcomming_count+")"}>
                            {
                                (activeTab === 'upcomingauction')
                                ?
                                    <UpcommingAuctionTable  listData={upcommingAuctions.data} loading={loading} paginationData={upcommingAuctions.pagination} sendRequestDataToParent={this.getBuyerUpcommingAuctions}
                                    
                                    />
                                :
                                    ''
                            }
                        </Tab>
                        <Tab eventKey="mybid" title={"My Bid ("+bid_count+")"}>
                            <div className="purchase-bg">
                            {
                                (activeTab === 'mybid')
                                ?
                                    <AuctionFilterCard auctionData={{updateInventoryList, inventoryCars: mybids, tabName: 'mybid', loading}} updateTabList={this.handleSelect} updateParentComponentState={this.updateComponentState} totalCount={bid_count}/>
                                :
                                    ''
                            }   
                            </div>
                        </Tab>
                        <Tab eventKey="wins" title={"Wins ("+win_count+")"}>
                            <div className="purchase-bg">
                                {/* <AuctionBidWin winAuction={wins} /> */}
                                {
                                    (activeTab === 'wins')
                                    ?
                                        <AuctionFilterCard auctionData={{updateInventoryList, inventoryCars: wins, tabName: 'wins', loading}} updateTabList={this.handleSelect} updateParentComponentState={this.updateComponentState} totalCount={win_count}/>
                                    :
                                        ''
                                }


                            </div>
                        </Tab>

                        <Tab eventKey="purchased" title={"Purchased ("+purchase_count+")"}>
                            <div className="purchase-bg">
                                {
                                    (activeTab === 'purchased')
                                    ?
                                        <PurchaseHistory auctionData={{inventoryCars: purchased, tabName: 'purchased'}} purchasedAuctions={purchased} updateTabList={this.handleSelect} totalCount={purchase_count}/>
                                    :
                                        ''
                                }
                            </div>

                        </Tab>
                        <Tab eventKey="watchlist" title={"Watchlist ("+watch_list_count+")"}>
                            <div className="purchase-bg">
                                {/* <WatchList watchListAucions={watchlist} /> */}

                                {
                                    (activeTab === 'watchlist')
                                    ?
                                        <AuctionFilterCard auctionData={{updateInventoryList, inventoryCars: watchlist, tabName: 'watchlist', loading}} updateTabList={this.handleSelect} updateParentComponentState={this.updateComponentState} totalCount={watch_list_count}/>
                                    :
                                        ''
                                }

                            </div>
                        </Tab>
                    </Tabs>
                    
                </div>
            </>

        );
    }
}

const mapStateToProps = state => {
    return {
      auctionAllData: state.auction || {}
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default  withTranslation('common')(connect(mapStateToProps, mapDispatchToProps) (AuctionTabCount));







